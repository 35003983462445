import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import {PypestreamChatConstants} from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import { getBackgroundImage } from '../Image/BackgroundImage/BackgroundImage.helpers'; //BackgroundImage.helpers
import { customJsonstringify } from '../../adapters/helpers/Utils';
import Modal from 'react-modal';
const Image = dynamic(() => import('../Image/Image'));

function PypestreamChat(props) {
    /* global Pypestream */

    const modalDelay = props.document?.fields?.modalDelay;
    const popUpDisabled = props.document?.fields?.popUpDisabled || false;
    const moduleId = props.document?.fields?.moduleId;
    const modalTitle = props.document?.fields?.modalTitle;
    const modalContent = props.document?.fields?.modalContent;
    const modalBackgroundImage = props.document?.fields?.modalBackgroundImage;
    const modalAcceptButtonLabel = props.document?.fields?.modalAcceptButtonLabel;
    const modalDeclineButtonLabel = props.document?.fields?.modalDeclineButtonLabel;
    const toggleChatLabel = props.document?.fields?.toggleChatLabel;
    const toggleButtonIcon = props.document?.fields?.toggleButtonIcon;
    const closeButtonIcon = props.document?.fields?.closeModel;
    const apiScript = props.document?.fields?.apiScriptUrl;
    const configId = props.document?.fields?.configId;
    const configDomain = props.document?.fields?.configDomain;
    const configEnv = props.document?.fields?.configEnv;
    const configBeta = props.document?.fields?.configBeta;
    const localStorageVariable =  PypestreamChatConstants.persistentChatTriggered + moduleId;
    const toggleButtonTab = props.document?.fields?.toggleButtonTab;
    const gtmId = props.document?.fields?.gtmId;
    const isEnableChat = props.document?.fields?.isEnableChat;
    const [url, setUrl] = useState(null);

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            background          : 'none',
            border              : 'none',
            borderRadius        : '0',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            padding             : '0',
            maxWidth            : '530px',
            transform           : 'translate(-50%, -50%)',
            width               : '90%'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWasVisited, setModalWasVisited] = useState(popUpDisabled);
    const [chatWasVisited, setChatWasVisited] = useState(false);
    const [visibility, setVisibility] = useState(true);
    const [macStyle, setMacStyle] = useState(false);

    const launchChat = (status, launchOpt) => {
        let chatContainer = document.getElementById(moduleId +'-module-container');
        closeModal();

        setChatWasVisited(true);

        let pypeSteamObj = {
            domain: configDomain,
            env: configEnv,
            beta: configBeta,
        }

        if(status) {
            const passthru = [
                {
                attribute: "launcher_cta_id",
                label: "launcher_cta_id",
                value: launchOpt,
                visible: "wpm"
                }
            ];
            pypeSteamObj = Object.assign({}, pypeSteamObj, { passthrough: customJsonstringify(passthru) });
            Pypestream('config', {...pypeSteamObj});
        } else {
            Pypestream('config', {
                domain: configDomain,
                env: configEnv,
                beta: configBeta,
                gtm_id: gtmId
            });
        }        

        Pypestream('boot', {
            APP_ID: configId
        }, chatContainer);
    };

    const toggleChat = (status, launchOpt = "") => {
        if(chatWasVisited) {
            Pypestream('toggle');
        } else {
            launchChat(status, launchOpt);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalWasVisited(true);
        localStorage.setItem(localStorageVariable, true);
    };

    const appendScript = () => {
        const script = document.createElement('script');
        script.src = apiScript;
        script.async = true;
        document.head.appendChild(script);
    };

    useEffect(() => {
        appendScript();
        const persistentChatTriggered = localStorage.getItem(localStorageVariable);
        const macOs = navigator && navigator.userAgent && navigator.userAgent.indexOf('Mac OS X') != -1;
        setMacStyle(macOs);

        if(toggleButtonTab && toggleButtonTab.fields && toggleButtonTab.fields.assetId) {
            setUrl(getBackgroundImage(toggleButtonTab.fields.assetId, toggleButtonTab?.fields?.imageRendition?.fields?.width, '', 'auto'));
        }

        if(!popUpDisabled) {
            setModalWasVisited(persistentChatTriggered ? true : false);
        }

        if (!persistentChatTriggered && !popUpDisabled) {
            setTimeout(() => {
                setModalIsOpen(true);
                
            }, (modalDelay*1000));
        }
    }, []);

    useEffect(() => {
        let launchOptOne = document.getElementById('launcher_option1');
        let launchOptTwo = document.getElementById('launcher_option2');
        if(launchOptOne) {
            console.log('launch element - launcher_option1: ', launchOptOne);
        } 
        if(launchOptTwo) {
            console.log('launch element - launcher_option2: ', launchOptTwo);
        }    
        // launchOptOne && launchOptOne.addEventListener('click', function() {
        //     console.log('launcher_option1 - Click event');
        //     toggleChat();
        // });

        // launchOptTwo && launchOptTwo.addEventListener('click', function() {
        //     console.log('launcher_option2 - Click event');
        //     toggleChat();
        // });
    }, []);

    const handleOnclick = () => {
        let launchOptOne = document.getElementById('launcher_option1') ? 'launcher_1' : '';
        let launchOptTwo = document.getElementById('launcher_option2') ? 'launcher_2' : '';
        if(launchOptOne || launchOptTwo) {            
            console.log('Click event - Chat - with DOM id');
            toggleChat(true, launchOptOne || launchOptTwo);
       } else {
            console.log('Click event - Chat - without DOM id');
            toggleChat(false);
       }
    }

    return (
        <div>
        {isEnableChat && modalWasVisited &&
            <div className={` ${macStyle ? 'mac-pypestream-toggle':''} ob-pypestream-toggle-button event_button_click ${visibility ? 'display-block' : 'hidden-block'}`}
                data-action-detail={AnalyticsConstants.openLiveChat}>
                <div id="close_pypestream" role='button' tabIndex={0} className="ob-app-store__close-button event_button_click" onClick={() => setVisibility()} onKeyDown={() => setVisibility()} 
                    aria-label={'close'}>
                    <Image image={closeButtonIcon} />
                </div>
                <div role="button" tabIndex={0} aria-label={toggleChatLabel.fields.text} onClick={() => handleOnclick()}>
                    <Image image={toggleButtonIcon} />
                    {/* <div style={{
                          backgroundImage: url
                    }} className="ob-chat-bot-bk">
                        <Image image={toggleButtonIcon} />
                        <div className="ob-chat-bot-title">{modalAcceptButtonLabel}</div>
                    </div> */}
                </div>
            </div>
        }

            {isEnableChat && <div className={'ob-pypestream-chat-container'}
                 id={moduleId + '-module-container'}>
            </div>}

            {!modalWasVisited &&
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={250}
                    style={customModalStyles}>
                    <div className='ob-pypestream-modal'>
                        <Image image={modalBackgroundImage}>
                            <div className='ob-pypestream-modal-content'>
                                <div className='ob-pypestream-modal-copy'>
                                    {modalTitle &&
                                        <p>{modalTitle}</p>
                                    }
                                    {modalContent &&
                                        <p>{modalContent}</p>
                                    }
                                </div>

                                <div className='ob-pypestream-modal-button-container'>
                                    <button aria-label={modalAcceptButtonLabel} className='ob-pypestream-modal-button launch'
                                            onClick={launchChat}>
                                        {modalAcceptButtonLabel}
                                    </button>

                                    <button aria-label={modalDeclineButtonLabel} className='ob-pypestream-modal-button close'
                                            onClick={closeModal}>
                                        {modalDeclineButtonLabel}
                                    </button>
                                </div>
                            </div>
                        </Image>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default PypestreamChat;

PypestreamChat.propTypes = {
    document: PropTypes.object
};